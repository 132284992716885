import React from "react";
import { Card, Col, Container, Row } from "reactstrap";

class Triangle extends React.Component {

    render() {
        return (
            <>
            <Container className="">
                <Row className="mx-5">
                    <Col>
                    <Card>
                    <a className="link-secondary" href="https://www.imdb.com/title/tt7322224/">
                        <h2 className="text-center">Triangle Of Sadness</h2>
                    </a>
                    </Card>
                    </Col>
                </Row>
                <Row className="">
                    <center>
                    <Col xs="12" lg="8">
                    <div class="bs-example" data-example-id="responsive-embed-16by9-iframe-youtube">
                    <div className="embed-responsive-item embed-responsive-16by9">
                    <iframe className="responsive-iframe" allowFullScreen="true" src="https://mega.nz/embed/1M8ymbYK#KYkumiTh6RFH_wV_o70t7sPIq8-TM091AwmGjRhJHyo" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" ></iframe>
                    </div>
                    </div>
                    </Col>
                    </center>
                </Row>
            </Container>
            </>
        )
    }
}

export default Triangle;
